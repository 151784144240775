export interface IColor {
    primaryColor: string,
    secondaryColor: string
}

export const colorCodes: IColor[] = [
    { primaryColor: "#EAF9E6", secondaryColor: "#2E5834" },
    { primaryColor: "#FFDCD6", secondaryColor: "#E54338" },
    { primaryColor: "#FFE5AC", secondaryColor: "#E3AC32" },
    { primaryColor: "#C6DFFF", secondaryColor: "#1955FD" },
]

export const socialMediaLink = {
    facebook: "https://www.facebook.com/jkoasl",
    instagram: "https://instagram.com/j_k_o_a",
    linkedin: "https://www.linkedin.com/company/john-keells-office-automation-pvt-ltd-",
    youtube: "https://www.youtube.com/user/JKOAPvtLtd",
}

export const productListingType = {
    department: "department",
    brand: "brand",
    departmentBrand: "departmentbrand",
    search: "search",
    all: "all",
    offer: "offer",
    depSubDep: "depsubdep"
}

export const cutomerPortal = 'https://jkoacloudc3db2fd07.ap1.hana.ondemand.com/jkoacloud/';
export const careerPortal = 'https://careers.keells.com/JKOA/go/Career-at-JKOA/516710/';

export const imageBaseUrl = 'https://sajkoaonline.blob.core.windows.net';